import { ADMIN_ROUTES } from '@admin/app/services/admin-navigation.service'
import { Component, OnInit } from '@angular/core'
import { PlatformAdminRouteData } from '@engineering11/platform-admin-web'
import { INavBottomTab, INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
@Component({
  selector: 'app-layout-authenticated',
  templateUrl: './layout-authenticated.component.html',
  styleUrls: ['./layout-authenticated.component.scss'],
})
export class AppLayoutAuthenticatedComponent implements OnInit {
  navigationList: INavigationItem[] = [
    {
      id: 'home',
      name: 'Home',
      route: ADMIN_ROUTES.HOME,
      icon: 'home',
      position: 'main',
    },
    {
      id: 'account',
      name: 'Account',
      route: ADMIN_ROUTES.ACCOUNT,
      icon: 'lock',
      position: 'main',
    },
    {
      name: 'Platform Admin',
      icon: 'user-cog',
      route: '/sdk',
      children: [
        {
          name: 'HTML Templates',
          icon: '',
          route: PlatformAdminRouteData.listNotificationTemplates.path,
        },
        {
          name: 'Notifications',
          route: PlatformAdminRouteData.listNotifications.path,
        },
        {
          name: 'Provision Customer Tenant',
          route: PlatformAdminRouteData.provisionCustomerTenant.path,
        },
        {
          name: 'Manage Business Tenants',
          route: PlatformAdminRouteData.listBusinessTenants.path,
        },
        {
          name: 'Manage Consumer Tenants',
          route: PlatformAdminRouteData.listCustomerTenants.path,
        },
        {
          name: 'Provision Customer',
          route: PlatformAdminRouteData.provisionCustomer.path,
        },
        {
          name: 'User Search',
          route: PlatformAdminRouteData.userSearch.path,
        },
        {
          name: 'Customer Search',
          route: PlatformAdminRouteData.customerSearch.path,
        },
      ],
    },
    {
      id: 'info_tips',
      name: 'Info and Tips',
      icon: 'tips_and_updates',
      position: 'footer',
    },
  ]

  mobileTabs: INavBottomTab[] = [
    {
      id: 'home',
      name: 'Home',
      routeGroup: 'main',
      icon: 'home',
    },
    {
      id: 'messages',
      name: 'Messages',
      routeGroup: 'main',
      icon: 'chat',
    },
  ]
  mobileDrawerState = false
  constructor() {}

  ngOnInit(): void {}
}
