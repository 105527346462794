import { ADMIN_ROUTES } from '@admin/app/services/admin-navigation.service'
import { Component, OnInit } from '@angular/core'
import { PlatformAdminNavigationService, PlatformAdminRouteData } from '@engineering11/platform-admin-web'
import { INavBottomTab, INavigationItem } from '@engineering11/ui-lib/e11-nav-left'

@Component({
  selector: 'layout-one',
  templateUrl: 'layout-one.component.html',
})
export class LayoutOneComponent implements OnInit {
  navigationList: INavigationItem[] = [
    {
      id: 'home',
      name: 'Home',
      route: ADMIN_ROUTES.HOME,
      icon: 'home',
      position: 'main',
    },
    {
      id: 'account',
      name: 'Account',
      route: ADMIN_ROUTES.ACCOUNT,
      icon: 'lock',
      position: 'main',
    },
    ...this.platformAdminNavigationService.menuItemActions,
    {
      id: 'info_tips',
      name: 'Info and Tips',
      icon: 'tips_and_updates',
      position: 'footer',
    },
  ]

  mobileTabs: INavBottomTab[] = [
    {
      id: 'home',
      name: 'Home',
      routeGroup: 'main',
      icon: 'home',
    },
  ]
  mobileDrawerState = false
  constructor(private platformAdminNavigationService: PlatformAdminNavigationService) {}

  ngOnInit() {}
}
