// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ApiGatewayPathBuilder, AppEnginePathBuilder, Env, IPlatformAdminEnvironment } from 'shared-lib'

const env: Env = 'demo'
const pathBuilder = new ApiGatewayPathBuilder(env)

export const environment: IPlatformAdminEnvironment = {
  production: false,
  env,
  firebaseConfig: {
    apiKey: 'AIzaSyBRNqXm82BMKEVWhs9uNjMkwnWTCAOcFoE',
    authDomain: 'cnect-demo-100.firebaseapp.com',
    projectId: 'cnect-demo-100',
    storageBucket: 'cnect-demo-100.appspot.com',
    messagingSenderId: '861440716888',
    appId: '1:861440716888:web:c43bc6dcb8b8c292bbda2d',
  },

  services: {
    jobs: pathBuilder.build('jobs'),
    registration: pathBuilder.build('registration'),
    user: pathBuilder.build('user'),
    auth: pathBuilder.build('auth'),
    files: pathBuilder.build('files'),
    customer: pathBuilder.build('customer'),
    notifications: pathBuilder.build('notifications'),
  },

  // We can turn authentication off in case we are using this application inside and SDK and do not want to have any authentication
  authentication: true, // Look to the auth guard for use of this value
  themesEnabled: true,
  v2ReCaptcha: '6LdL9f4bAAAAAFVMFNz49EExxbLn-T9KMrDIXg1W',
  tenantId: 'PLATFORM-USER-2kfs5',
  algoliaAppId: '3YG4F5RXCG',
  cdnCname: 'demo-content.cnected.com',
  version: 'fca57c7',
  basePlatformHost: 'demo-admin.cnect.jobs',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
